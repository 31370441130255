<script lang="ts" setup>
import AppConfig from '~/components/layout/AppConfig.vue';

const { toggleConfigSidebar } = useLayout();
</script>

<template>
  <main class="min-h-screen">
    <section class="animate-fadein animate-duration-300 animate-ease-in relative lg:pb-14 lg:py-52 py-36">
      <div class="landing-container mx-auto relative z-10 px-12">
        <div class="relative mt-28 max-w-[46rem] mx-auto">
          <div class="w-full h-full inset-0 bg-white/64 dark:bg-surface-800 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[4deg] lg:rotate-[7deg] backdrop-blur-[90px] rounded-3xl shadow-[0px_87px_24px_0px_rgba(120,149,206,0.00),0px_56px_22px_0px_rgba(120,149,206,0.01),0px_31px_19px_0px_rgba(120,149,206,0.03),0px_14px_14px_0px_rgba(120,149,206,0.04),0px_3px_8px_0px_rgba(120,149,206,0.06)] dark:shadow-sm" />
          <div class="w-full h-full inset-0 bg-white/64 dark:bg-surface-800 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-[4deg] lg:-rotate-[7deg] backdrop-blur-[90px] rounded-3xl shadow-[0px_87px_24px_0px_rgba(120,149,206,0.00),0px_56px_22px_0px_rgba(120,149,206,0.01),0px_31px_19px_0px_rgba(120,149,206,0.03),0px_14px_14px_0px_rgba(120,149,206,0.04),0px_3px_8px_0px_rgba(120,149,206,0.06)] dark:shadow-sm" />
          <div class="space-y-8 p-8 relative z-10 bg-white/64 dark:bg-surface-800 backdrop-blur-[90px] rounded-3xl shadow-[0px_87px_24px_0px_rgba(120,149,206,0.00),0px_56px_22px_0px_rgba(120,149,206,0.01),0px_31px_19px_0px_rgba(120,149,206,0.03),0px_14px_14px_0px_rgba(120,149,206,0.04),0px_3px_8px_0px_rgba(120,149,206,0.06)]">
            <div class="pt-8 pb-8">
              <div class="flex items-center justify-center">
                <img class="h-[45px]" src="/img/rhinocalls-192x192.png" alt="RhinoCalls" />
                <span class="font-bold text-5xl">RhinoCalls</span>
              </div>
            </div>

            <NuxtPage />
          </div>
        </div>
      </div>
    </section>
  </main>
  <button class="layout-config-button config-link" @click="toggleConfigSidebar">
    <i class="pi pi-cog" />
  </button>
  <AppConfig location="landing" />
</template>
